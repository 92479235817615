
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import { reduce } from "lodash";

interface editSetting {
  title: string;
  item: string;
  defaultPrice: any;
  pricee: string;
  tax: string;
  expiredInDays: string;
  customPrice: any;
  schoolId: string;
  idDiskon: string;
  namaDiskon: string;
  banyakDiskon: string;
  expDateDiskon: string;
  statusCustomPrice: string;
  statusSaveCustomPrice: string;
}

interface items {
  listSchool: any;
  listProduct: any;
  customPrice: any;
  listDiskon: any;
  roleLogin: string;
  endPointUpdate: string;
  endPointAddCustom: string;
  endPointUpdateCustom: string;
}

interface errorsMessage {
  errorschoolId: string;
  errortitle: string;
  erroritem: string;
  errornamadiskon: string;
  errortanggaldiskon: string;
  errorbanyakdiskon: string;
}

export default defineComponent({
  name: "Setting Finance",
  components: {
    ErrorMessage,
    Field,
    Loading,
    Form,
  },

  setup() {
    // loading overlay
    const uploadModalRef = ref<null | HTMLElement>(null);
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const store = useStore();
    const route = useRoute();

    let items = reactive<items>({
      listSchool: [],
      listProduct: [],
      customPrice: [],
      listDiskon: [],
      roleLogin: "",
      endPointUpdate: "",
      endPointAddCustom: "",
      endPointUpdateCustom: "",
    });

    const editSettingValidator = Yup.object().shape({
      // schoolId: Yup.string().required('Nama Sekolah Wajib Diisi'),
      // title: Yup.string().required('Nama Setting Wajib Diisi'),
      // discountName: Yup.string().required('Nama Diskon Wajib Diisi'),
      // expiredDate: Yup.string().required('Exp Date Wajib Diisi'),
      // discount: Yup.string().required('Diskon Wajib Diisi'),
      // tax: Yup.string().required('Pajak wajib Diisi'),
    });

    const editSetting = reactive<editSetting>({
      title: "",
      schoolId: "",
      item: "",
      pricee: "",
      defaultPrice: "",
      tax: "",
      expiredInDays: "",
      customPrice: [],
      idDiskon: "",
      namaDiskon: "",
      banyakDiskon: "",
      expDateDiskon: "",
      statusCustomPrice: "",
      statusSaveCustomPrice: "",
    });

    const errorsMessage = reactive<errorsMessage>({
      errorschoolId: "",
      errortitle: "",
      erroritem: "",
      errornamadiskon: "",
      errortanggaldiskon: "",
      errorbanyakdiskon: "",
    });

    const handleDelete = (index, item) => {
      Swal.fire({
        title: "Apakah anda yakin akan melakukan menghapus custom price ?",
        text: `Menghapus custom price akan menghapus data custom price beserta detail diskon`,
        color: "red",
        type: "danger",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          deleteRow(item);
        }
      });
    };

    const deleteRow = (item) => {
      isLoading.value = true;
      const toast = useToast();
      var newParamReq = "";
      if (editSetting.schoolId != "") {
        newParamReq = editSetting.schoolId;
      }
      ApiService.delete(
        "crmv2/main_invoice/invoice/office/invoice/settings/custom/" +
          route.params._id +
          "?schoolId=" +
          newParamReq
      )
        .then((res) => {
          toast.success("Hapus Data Berhasil");

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })

        .catch((error) => {
          toast.error("Gagal Hapus Data");
          isLoading.value = false;
        });
    };

    const setRoleLogin = () => {
      items.roleLogin = JSON.parse(localStorage.getItem("user_account")!).role;
      switch (items.roleLogin) {
        case "SUPER_ADMIN":
          items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
        case "SALES_LEADER":
        items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
        case "SALES_AREA":
        items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
        case "CS_LEADER":
        items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
        case "CS_MEMBER":
        items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
        case "ADMIN_FINANCE":
        items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
      }
    };

    const getListSchool = () => {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        (response) => {
          items.listSchool = response.data;
        }
      );
    };

    const getlistproduk = () => {
      const itemsproduk = [
        { name: "Registration", id: "Registration" },
        { name: "Migration", id: "Migration" },
        { name: "Order", id: "Order" },
        { name: "Custom App", id: "Custom App" },
        { name: "Monthly Fee", id: "Monthly Fee" },
        { name: "Package", id: "Package" },
        { name: "Other", id: "Other" },
        { name: "Topup Manual", id: "Topup Manual" },
        { name: "Product", id: "Product" },
      ];
      items.listProduct = itemsproduk;
    };

    const changeProduct = () => {
      console.log(editSetting.item);
      if (editSetting.item == "Registration") editSetting.defaultPrice = 20000;
      else editSetting.defaultPrice = 0;
    };

    const getIdInvoice = () => {
      isLoading.value = true;
      // console.log(editSetting.schoolId)
      var newParamReq = "";
      if (editSetting.schoolId != "") {
        newParamReq = "?schoolId=" + editSetting.schoolId;
      }
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/invoice/settings/" +
          route.params._id +
          newParamReq
      )
        .then((res) => {
          // editSetting.schoolId = res.data.schoolId;
          editSetting.title = res.data.title;
          editSetting.item = res.data.item;
          editSetting.tax = res.data.tax;
          editSetting.defaultPrice = res.data.defaultPrice;
          editSetting.expiredInDays = res.data.expiredInDays;

          // editSetting.statusCustomPrice = "not_ready";
          // if (res.data.customPrice.length > 0) {
          //   editSetting.statusCustomPrice = "ready";
          //   editSetting.idDiskon = res.data.customPrice[0].id;
          //   editSetting.namaDiskon = res.data.customPrice[0].title;
          //   editSetting.banyakDiskon = res.data.customPrice[0].discount;
          //   editSetting.expDateDiskon = res.data.customPrice[0].dueDate;
          // }
          // console.log(editSetting.schoolId)
          // getdiscInvoice();
          isLoading.value = false;
        })
        .catch(({ response }) => {
          isLoading.value = false;
        });
    };

    const getdiscInvoice = () => {
      isLoading.value = true;
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/invoice/settings/custom/" +
          route.params._id +
          "?schoolId=" +
          editSetting.schoolId
      )
        .then((res) => {
          console.log(res);

          editSetting.statusCustomPrice != "ready";
          editSetting.pricee = res.data.price;
          if (res.data.discounts[0].discount != 0) {
            editSetting.statusCustomPrice = "ready";
            editSetting.idDiskon = res.data.discounts[0].id;
            editSetting.namaDiskon = res.data.discounts[0].title;
            editSetting.banyakDiskon = res.data.discounts[0].discount;
            editSetting.expDateDiskon = res.data.discounts[0].dueDate;
            editSetting.pricee = res.data.price;
          }
          isLoading.value = false;

          // console.log(res.data.customPrice[0].title);
          // items.customPrice.title = res.data.customPrice.title;
          // items.customPrice.discount = res.data.customPrice.discount;
          // items.customPrice.dueDate = res.data.customPrice.dueDate;
        })
        .catch(({ response }) => {
          isLoading.value = false;
        });
    };

    const getdiscInvoicee = () => {
      isLoading.value = true;
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/invoice/settings/custom/" +
          route.params._id +
          "?schoolId=" +
          editSetting.schoolId
      )
        .then((res) => {
          console.log(res);

          editSetting.statusCustomPrice = "not_ready";
          if ((res.data.discounts[0].discount = 0)) {
            editSetting.statusCustomPrice = "readyy";

            editSetting.pricee = res.data.price;
          } else {
            editSetting.pricee = res.data.price;
          }

          isLoading.value = false;

          // console.log(res.data.customPrice[0].title);
          // items.customPrice.title = res.data.customPrice.title;
          // items.customPrice.discount = res.data.customPrice.discount;
          // items.customPrice.dueDate = res.data.customPrice.dueDate;
        })
        .catch(({ response }) => {
          isLoading.value = false;
        });
    };

    const saveChanges1 = () => {
      if (
        // editSetting.schoolId === "" ||
        editSetting.title === "" ||
        editSetting.item === ""
      ) {
        // if (editSetting.schoolId === "") {
        //   errorsMessage.errorschoolId = "Sekolah Wajib Diisi";
        // }
        if (editSetting.title === "") {
          errorsMessage.errortitle = "Nama Produk Wajib Diisi";
        }
        if (editSetting.item === "") {
          errorsMessage.erroritem = "Tipe Produk Wajib Diisi";
        }
        toast.error("Form belum lengkap");
        // items.errorfile = "File Dokumen Wajib Diisi";
      } else {
        isLoading.value = true;
        errorsMessage.errorschoolId = "";
        errorsMessage.errortitle = "";
        errorsMessage.erroritem = "";

        // editSetting.customPrice = listDiskon;
        const dataPost = {
          // schoolId: editSetting.schoolId,
          title: editSetting.title,
          // item: editSetting.item,
          defaultPrice: editSetting.defaultPrice,
          expiredInDays: editSetting.expiredInDays,
          tax: editSetting.tax,
        };

        submitButton.value?.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          // Send login request
          ApiService
            // .putWithData('crmv2/main_invoice/invoice/office/invoice/setting/'+ route.params._id + '&itemType=Registration', editSetting)
            .putWithData(
              // "crmv2/main_invoice/invoice/office/invoice/setting/" +
              items.endPointUpdate + route.params._id,
              dataPost
            )
            .then((res) => {
              // cekSuccess = true;
              toast.success("Berhasil Edit Setting Invoices");
              router.push({ path: "/superadmin/setting-invoices" });
              isLoading.value = false;
            })
            .catch((e) => {
              toast.error('Form tidak boleh kosong');
              isLoading.value = false;
            });

          submitButton.value?.removeAttribute("data-kt-indicator");
        }, 1000);
      }
    };
    const closeModal = () => {
      window.location.reload();
    };
    const openaddmodal = () => {
      console.log("testadd");
      editSetting.statusSaveCustomPrice = "add";
      editSetting.idDiskon = "";
      editSetting.namaDiskon = "";
      editSetting.expDateDiskon = "";
      editSetting.banyakDiskon = "";
      errorsMessage.errornamadiskon = "";
      errorsMessage.errorbanyakdiskon = "";
      errorsMessage.errortanggaldiskon = "";
    };
    const openeditmodal = () => {
      console.log("testedit");
      editSetting.statusSaveCustomPrice = "edit";
    };
    const saveModal = () => {
      if (
        editSetting.namaDiskon === "" ||
        editSetting.banyakDiskon === "" ||
        editSetting.expDateDiskon === ""
      ) {
        if (editSetting.namaDiskon === "") {
          errorsMessage.errornamadiskon = "Nama Diskon Wajib Diisi";
        }
        if (editSetting.banyakDiskon === "") {
          errorsMessage.errorbanyakdiskon = "Diskon Wajib Diisi";
        }
        if (editSetting.expDateDiskon === "") {
          errorsMessage.errortanggaldiskon = "Tangal Diskon Wajib Diisi";
        }
        toast.error("Form belum lengkap");
        // items.errorfile = "File Dokumen Wajib Diisi";
      } else {
        // isLoading.value = true;
        errorsMessage.errornamadiskon = "";
        errorsMessage.errorbanyakdiskon = "";
        errorsMessage.errortanggaldiskon = "";

        if (
          editSetting.idDiskon &&
          editSetting.statusSaveCustomPrice == "edit"
        ) {
          console.log("saveedit");
          isLoading.value = true;
          // const listDiskon = {
          //   id: editSetting.idDiskon,
          //   title: editSetting.namaDiskon,
          //   dueDate: editSetting.expDateDiskon,
          //   discount: parseInt(editSetting.banyakDiskon),
          // };

          const listDiskon = {
            schoolId: editSetting.schoolId,
            price: editSetting.pricee,
            discounts: [
              {
                id: editSetting.idDiskon,
                title: editSetting.namaDiskon,
                dueDate: editSetting.expDateDiskon,
                discount: parseInt(editSetting.banyakDiskon),
              },
            ],
          };

          setTimeout(() => {
            // Send login request
            ApiService
              // .putWithData('crmv2/main_invoice/invoice/office/invoice/setting/'+ route.params._id + '&itemType=Registration', editSetting)
              .putWithData(
                // "crmv2/main_invoice/invoice/office/invoice/setting/custom/" +
                items.endPointUpdateCustom +
                  route.params._id +
                  "?idCustomPrice=" +
                  editSetting.idDiskon,
                listDiskon
              )
              .then((res) => {
                // cekSuccess = true;
                toast.success("Berhasil Edit Diskon Invoices");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                // hideModal(uploadModalRef.value);
                getdiscInvoice();
                // router.push({ path: "/superadmin/setting-invoices" });
                isLoading.value = false;
              })
              .catch((e) => {
                toast.error(e.response.data.detail);
                isLoading.value = false;
              });

            submitButton.value?.removeAttribute("data-kt-indicator");
          }, 1000);
        } else {
          console.log("saveadd");
          isLoading.value = true;
          // const listDiskon = {
          //   title: editSetting.namaDiskon,
          //   dueDate: editSetting.expDateDiskon,
          //   discount: parseInt(editSetting.banyakDiskon),
          // };

          const listDiskon = {
            schoolId: editSetting.schoolId,
            price: editSetting.pricee,
            discounts: [
              {
                // id: editSetting.idDiskon,
                title: editSetting.namaDiskon,
                dueDate: editSetting.expDateDiskon,
                discount: parseInt(editSetting.banyakDiskon),
              },
            ],
          };

          setTimeout(() => {
            // Send login request
            ApiService
              // .putWithData('crmv2/main_invoice/invoice/office/invoice/setting/'+ route.params._id + '&itemType=Registration', editSetting)
              .postWithData(
                // "crmv2/main_invoice/invoice/office/invoice/setting/custom?invSettingId=" +
                items.endPointAddCustom + "?invSettingId=" + route.params._id,
                listDiskon
              )
              .then((res) => {
                // cekSuccess = true;
                toast.success("Berhasil Add Custom Diskon Invoices");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                getdiscInvoice();

                // router.push({ path: "/superadmin/setting-invoices" });
                isLoading.value = false;
              })
              .catch((e) => {
                toast.error(e.response.data.detail);
                isLoading.value = false;
              });

            submitButton.value?.removeAttribute("data-kt-indicator");
          }, 1000);
        }
      }
      isLoading.value = false;
    };

    const saveModalcustom = () => {
      if (editSetting.idDiskon && editSetting.statusSaveCustomPrice == "edit") {
        console.log("saveedit");
        isLoading.value = true;
        // const listDiskon = {
        //   id: editSetting.idDiskon,
        //   title: editSetting.namaDiskon,
        //   dueDate: editSetting.expDateDiskon,
        //   discount: parseInt(editSetting.banyakDiskon),
        // };

        const listDiskon = {
          schoolId: editSetting.schoolId,
          price: editSetting.pricee,
          discounts: [
            {
              id: editSetting.idDiskon,
              title: editSetting.namaDiskon,
              dueDate: editSetting.expDateDiskon,
              discount: parseInt(editSetting.banyakDiskon),
            },
          ],
        };
        console.log(listDiskon);
        setTimeout(() => {
          // Send login request
          ApiService
            // .putWithData('crmv2/main_invoice/invoice/office/invoice/setting/'+ route.params._id + '&itemType=Registration', editSetting)
            .putWithData(
              // "crmv2/main_invoice/invoice/office/invoice/setting/custom/" +
              items.endPointUpdateCustom +
                route.params._id +
                "?idCustomPrice=" +
                editSetting.idDiskon,
              listDiskon
            )
            .then((res) => {
              // cekSuccess = true;
              toast.success("Berhasil Edit Custom Price");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // hideModal(uploadModalRef.value);
              getdiscInvoice();
              // router.push({ path: "/superadmin/setting-invoices" });
              isLoading.value = false;
            })
            .catch((e) => {
              toast.error("Custom Price tidak boleh kosong");
              isLoading.value = false;
            });

          submitButton.value?.removeAttribute("data-kt-indicator");
        }, 1000);
      } else {
        console.log("saveadd");
        isLoading.value = true;
        // const listDiskon = {
        //   title: editSetting.namaDiskon,
        //   dueDate: editSetting.expDateDiskon,
        //   discount: parseInt(editSetting.banyakDiskon),
        // };

        const listDiskon = {
          schoolId: editSetting.schoolId,
          price: editSetting.pricee,
          discounts: [],
        };
        setTimeout(() => {
          // Send login request
          ApiService
            // .putWithData('crmv2/main_invoice/invoice/office/invoice/setting/'+ route.params._id + '&itemType=Registration', editSetting)
            .putWithData(
              // "crmv2/main_invoice/invoice/office/invoice/setting/custom/" +
              items.endPointUpdateCustom +
                route.params._id +
                "?idCustomPrice=" +
                editSetting.idDiskon,
              listDiskon
            )
            .then((res) => {
              // cekSuccess = true;
              toast.success("Berhasil Edit Custom Price ");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // hideModal(uploadModalRef.value);
              getdiscInvoice();
              // router.push({ path: "/superadmin/setting-invoices" });
              isLoading.value = false;
            })
            .catch((e) => {
              toast.error("Custom Price tidak boleh kosong");
              isLoading.value = false;
            });

          submitButton.value?.removeAttribute("data-kt-indicator");
        }, 1000);
      }
      isLoading.value = false;
    };

    const deletePrice = (index, item) => {
      Swal.fire({
        title: "Konfirmasi",
        text: `Apakah anda yakin akan melakukan menghapus detail diskon ?`,
        color: "red",
        type: "danger",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          deleteRow(item);
        }
      });
      const listDiskon = {
        schoolId: editSetting.schoolId,
        price: editSetting.pricee,
        discounts: [],
      };

      const deleteRow = (item) => {
        isLoading.value = true;
        const toast = useToast();
        var newParamReq = "";
        if (editSetting.schoolId != "") {
          newParamReq = editSetting.schoolId;
        }
        ApiService
          // .putWithData('crmv2/main_invoice/invoice/office/invoice/setting/'+ route.params._id + '&itemType=Registration', editSetting)
          .putWithData(
            // "crmv2/main_invoice/invoice/office/invoice/setting/custom/" +
            items.endPointUpdateCustom +
              route.params._id +
              "?idCustomPrice=" +
              editSetting.idDiskon,
            listDiskon
          )
          .then((res) => {
            // cekSuccess = true;
            toast.success("Berhasil Hapus Data Diskon");
            setTimeout(() => {
              window.location.reload();
            }, 1000);

            // router.push({ path: "/superadmin/setting-invoices" });
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          });
      };
    };

    function getLocalStorage() {
      const cekShoolId = localStorage.getItem("schoolId");
      // console.log('cekShoolId->',cekShoolId);
      // if(cekShoolId == 'null'){
      //   console.log('null')
      // }
      if (cekShoolId != "null") {
        editSetting.schoolId = cekShoolId !== null ? cekShoolId : "";
        getdiscInvoice();
        // console.log('isi')
      } else {
        editSetting.schoolId = "";
      }
    }

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_FINANCE);
      setCurrentPageBreadcrumbs("Ubah Setting Invoice", ["Data"]);
      getLocalStorage();
      setRoleLogin();
      getListSchool();
      getIdInvoice();
      getlistproduk();
    });

    return {
      submitButton,
      saveChanges1,
      handleDelete,
      openaddmodal,
      deletePrice,
      closeModal,
      openeditmodal,
      deleteRow,
      getdiscInvoice,
      getdiscInvoicee,
      saveModal,
      saveModalcustom,
      uploadModalRef,
      editSetting,
      editSettingValidator,
      items,
      errorsMessage,
      setRoleLogin,
      getIdInvoice,
      getListSchool,
      getlistproduk,
      changeProduct,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
      getLocalStorage,
    };
  },
});
